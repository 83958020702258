.social-grid {
  @include listUnstyled;
  display: flex;
  flex-wrap: wrap;
  margin-top: $minContainerPadding;

  @include respond($break30) {
    margin-top: 12px;
    margin-left: -6px;
    margin-right: -6px;
  }

  .social-grid-item {
    display: flex;
    width: 100vw;
    min-height: 100vw;
    padding-left: $minContainerPadding;
    padding-right: $minContainerPadding;
    padding-bottom: $minContainerPadding;

    @include respond($break30) {
      width: 50vw;
      min-height: 50vw;
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 12px;
    }

    @include respond($break90) {
      width: 25vw;
      min-height: 25vw;
    }

    @include respond($break100) {
      width: $containerWidth * 0.25;
      min-height: $containerWidth * 0.25;
    }

    .social-thumb {
      display: block;
      position: relative;
      width: 100%;
      background-color: $accentQuaternary;
    }

    .social-pic {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .post {
      padding: 60px 30px 30px 30px;
      @include font-body;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: .1em;

      @include respond($break90) {
        padding: 8.6vw 2.866vw 2.866vw 2.866vw;
      }

      @include respond($break100) {
        padding: 120px 40px 40px 40px;
      }

      .post-date {
        padding-bottom: 8px;
        font-weight: $bold;
        text-transform: uppercase;
        color: $accentSecondary;

        @include respond($break70) {
          font-size: 14px;
          line-height: 1.29;
        }
      }

      .post-body {
        font-weight: $regular;
        color: $accentTertiary;
      }
    }

    .social-grid-item-overlay {
      @include transition($timing opacity $durationSlow);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;

      @include respond($break30) {
        position: absolute;
        opacity: 0;
        &:hover {
          opacity: 1;
        }
      }

      .overlay-background {
        height: 100%;
        width: 100%;

        @include respond($break30) {
          position: absolute;
          opacity: 0.9;
        }
      }

      .social-thumb-info {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 20px 18px 0px;
        text-align: center;

        @include respond($break30) {
          display: block;
          padding: 18px 30px;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 100%;
          vertical-align: middle;
        }

        .social-name {
          @include font-body;
          display: inline-block;
          font-size: 15px;
          font-weight: $bold;
          text-transform: uppercase;
        }

        .icon-circle-caret-right {
          display: inline-block;
          line-height: 0;

          &:before {
            padding-right: 8px;
            margin-top: -2px;
            font-size: 20px;
            vertical-align: middle;
          }
        }
      }
    }

    &.linked-in {
      .overlay-background {
        background-color: $accentSecondary;
      }

      .social-thumb-info {
        color: $white;
      }
    }

    &.facebook {
      .overlay-background {
        background-color: $white;
      }

      .social-thumb-info {
        color: $accentSecondary;
      }
    }
  }
}


.m-markets-served {
  padding-inline-start: $contentContainerPadding * 0.4;
  padding-inline-end: $contentContainerPadding * 0.4;

  &__header {
    max-width: 650px;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  &__subheader {
    margin-block-start: 20px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 30px;
    margin-block-start: 60px;
    margin-block-end: 1rem;
    padding: 0;
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 110px;
    text-align: center;
    padding: 0 !important;
  }
}

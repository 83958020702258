// Variables

//----------

// Colors
// --------------------------------------------------------
// Neutrals
$white: #ffffff;
$black: #000000;

$grayMedium: #a6a8ab;
$grayLight: #dedfe0;
$blueDark: #002441;

// Accent Colors
$accentPrimary: #003056;
$accentSecondary: #ec7823;
$accentSecondaryLight: #ee853e;
$accentTertiary: #55565a;
$accentTertiaryLight: #747474;
$accentQuaternary: #f2f2f3;

// Typographic hierarchy
// --------------------------------------------------------
// $type110: ;
// $type100: ;
// $type90: ;
// $type80: ;
// $type70: ;
// $type60: ;
// $type50: ;
// $type40: ;
// $type30: ;
// $type20: ;
// $type10: ;

// Measurements
// --------------------------------------------------------
$containerWidth: 1396px;

$maxContainerPadding: 160px;
$containerPadding: 11.47vw;
$minContainerPadding: 18px;

$maxContentContainerPadding: 190px;
$contentContainerPadding: 13.61vw;
$minContentContainerPadding: $minContainerPadding;

$baseLineHeight: 1.4;
$verticalSpace: 1rem;

$durationFast: 0.1s;
$duration: 0.2s;
$durationSlow: 0.4s;
$timing: ease;

// Font Weights
// --------------------------------------------------------
$light: 300;
$regular: 400;
$bold: 600;

// Responsive breakpoints
// --------------------------------------------------------
// $break130: ;
// $break120: ;
// $break110: ;
$break100: $containerWidth;
$break95: 1280px;
$break90: 900px;
// $break80: ;
$break70: 768px;
$break60: 600px;
// $break50: ;
// $break40: ;
$break30: 500px;
$break20: 420px;
$break10: 281px; // Galaxy fold

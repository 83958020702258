.m-event-date {
  --event-date-height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--event-date-height);
  height: var(--event-date-height);
  min-height: var(--event-date-height);
  background-color: $accentPrimary;
  color: $white;

  &--large {
    --event-date-height: 220px;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
    z-index: 0;
  }

  &__day,
  &__month {
    z-index: 1;
  }

  &__day {
    @include font-body;
    font-size: 60px;
    line-height: 1;
    font-weight: $light;
    letter-spacing: normal;
  }

  &__month {
    @include subheadingTertiary;
    color: $accentSecondary;
  }
}

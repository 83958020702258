// Donezo Generated Icon File:
// File will be overwritten if regenerated

// Icon Variables
// --------------------------------------------------------
$bim: "\e600";
$caretUp: "\e601";
$caretLeft: "\e602";
$caretDown: "\e603";
$caretRight: "\e604";
$caretLargeLeft: "\e605";
$caretLargeRight: "\e606";
$circleCaretUp: "\e607";
$circleCaretDown: "\e608";
$circleCaretRight: "\e609";
$close: "\e60a";
$collaboration: "\e60b";
$communication: "\e60c";
$construction: "\e60d";
$cooperation: "\e60e";
$designBuild: "\e60f";
$email: "\e610";
$exploreWork: "\e611";
$fairness: "\e612";
$feasibility: "\e613";
$grid: "\e614";
$hamburger: "\e615";
$magnifyingGlass: "\e616";
$preconstruction: "\e617";
$preplanning: "\e618";
$remodels: "\e619";
$repair: "\e61a";
$socialFacebook: "\e61b";
$socialInstagram: "\e61c";
$socialLinkedin: "\e61d";
$socialTwitter: "\e61e";
$socialYoutube: "\e61f";
$teamwork: "\e620";
$testimonial: "\e621";

@font-face {
  font-family: "deacon-icons";
  src: url("../fonts/deacon-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/deacon-icons.woff") format("woff"),
    url("../fonts/deacon-icons.ttf") format("truetype"),
    url("../fonts/deacon-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin fontIcon {
  font-family: "deacon-icons", sans-serif;
}

@mixin protoIcon {
  @include fontIcon;
  display: inline-block;
  vertical-align: baseline;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
}

@mixin protoIconImage {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 95px;
  height: 81px;
}

@mixin iconPre($icon) {
  speak: none;

  &::before {
    @include protoIcon;
    content: $icon;
  }
}

@mixin iconPost($icon) {
  speak: none;

  &::after {
    @include protoIcon;
    content: $icon;
  }
}

@mixin iconImagePre($iconPath) {
  &::before {
    @include protoIconImage;
    background-image: url($iconPath);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-block-end: 60px;

  @include respond($break70) {
    padding-block-end: calc(6.5vw + 10px);
  }

  @include respond($break100) {
    padding-block-end: 100px;
  }

  > li {
    @include subheadingQuaternary;

    & + li {
      margin-inline-start: 6px;
    }

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      min-width: 32px;
      border: 1px solid $accentTertiary;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
      color: inherit;

      @include transition(
        $timing background-color $duration,
        $timing opacity $duration
      );

      &:hover {
        background-color: $accentQuaternary;
      }
    }

    &.disabled > * {
      cursor: default;
      opacity: 0.7;
    }

    &.active > * {
      cursor: default;
      border-color: $accentSecondary;
      background-color: $accentSecondary;
      color: $white;
    }
  }
}

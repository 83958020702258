// Component Structure
// -------------------

// Structure-only classes, with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context should use EM units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms


// Utilities
// --------------------------------------------------------

.overlay-active, .mobile-filters-active {
  overflow: hidden;
}


.overlay-active .sticky {
  .sub-nav-wrapper, .secondary-nav-wrapper {
    visibility: hidden;
  }
}

// Layout
// --------------------------------------------------------
.main-container {
  max-width: $containerWidth;
  margin: 0 auto;
}

.section-container {
  max-width: $containerWidth;
  padding: 0 $minContainerPadding;
  margin: 0 auto;

  @include respond($break20) {
    padding: 0 $containerPadding;
  }

  @include respond($break100) {
    padding: 0 $maxContainerPadding;
  }
}

.content-container {
  max-width: $containerWidth;
  padding: 0 $minContentContainerPadding;
  margin: 0 auto;

  @include respond($break20) {
    padding: 0 $contentContainerPadding;
  }

  @include respond($break100) {
    padding: 0 $maxContentContainerPadding;
  }

  &.with-image, &.with-icon {
    padding: 50px $minContentContainerPadding;

    @include respond($break60) {
      min-height: 380px;
    }

    @include respond($break70) {
      padding: 6.4vw $contentContainerPadding 6.4vw $contentContainerPadding * 0.4;
    }

    @include respond($break100) {
      padding: 90px $maxContentContainerPadding 90px $maxContentContainerPadding * 0.4;
    }
  }
}

.error-container {
  max-width: $containerWidth;
  padding: 25px $minContentContainerPadding 40px;
  margin: 0 auto;

  @include respond($break20) {
    padding: 25px $contentContainerPadding 40px;
  }

  @include respond($break100) {
    padding: 25px $maxContentContainerPadding 40px;
  }
}

.header-container {
  padding: 35px 45px;
}

.footer-container {
  @include clearfix;
  max-width: $containerWidth;
  padding: 20px $minContainerPadding;
  margin: 0 auto;

  @include respond($break20) {
    padding: 4.77vw 4.296vw;
  }

  @include respond($break60) {
    padding: 30px 45px;
  }
}

.project-vitals {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: $accentQuaternary;

  .project-heading {
    @include subheadingPrimary;
    padding-bottom: 15px;
  }

  .details-list {
    @include listUnstyled;
    border-top: 4px solid $grayLight;

    @include respond($break30) {
      display: flex;
      justify-content: space-between;
      border-top: 0;
    }

    .detail {
      display: block;
      padding-top: 17px;

      @include respond($break30) {
        width: 23.2%;
        border-top: 4px solid $grayLight;
      }

      .detail-heading {
        @include subheadingQuaternary;
        color: $accentSecondary;
        padding-bottom: 5px;
      }
    }
  }

  .locations, .categories {
    @include listUnstyled;

    .location, .category {
      display: inline;

      + .location, + .category {
        @include copySecondary;
      }
    }
  }
}

.team-grid-wrapper {
  // margin-left: -12.5px;
  // margin-right: -12.5px;
  overflow-x: hidden;
}

.team-grid {
  @include clearfix;
  @include listUnstyled;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12.5px;
  margin-right: -12.5px;

  .team-member {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 25px;

    @include respond($break30) {
      width: 50%;
      padding-left: 12.5px;
      padding-right: 12.5px;
    }

    @include respond($break90) {
      width: 33.33%;
    }

    .icon-email {
      padding-right: 10px;
      vertical-align: middle;
      color: $accentSecondary;
    }

    .team-member-name {
      @include headingTertiary;
      color: $accentPrimary;
    }

    .team-member-department {
      @include subheadingQuaternary;
      color: $accentSecondary;
      padding-top: 10px;

      .vertical-divider {
        display: inline-block;
        padding: 0 10px;
      }
    }

    .team-member-thumb {
      position: relative;
      display: none;
      border: 1px solid transparent;
      overflow: hidden;
      z-index: 0;

      @include respond($break30) {
        display: block;

        &:hover, &.active {
          .team-member-portrait {
            transform: scale(1.1);
          }
        }
      }

      &.mobile {
        display: block;

        @include respond($break30) {
          display: none;
        }
      }
    }

    .team-member-portrait {
      @include transition($timing transform $durationSlow);
      position: relative;
      z-index: -1;
      display: block;
      width: 100%;
      height: 0;
      padding-top: 66.96%;
      background-size: cover;
      background-repeat: no-repeat;
      transform: scale(1);
      border: 1px solid transparent;
    }

    .team-member-overlay {
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;

      @include respond($break30) {
        position: absolute;

        .team-member-thumb-info {
          visibility: hidden;
        }

        &:hover, &.active {
          .overlay-background {
            opacity: 0.9;
          }

          .team-member-thumb-info {
            bottom: 0;
            visibility: visible;
          }
        }
      }

      .overlay-background {
        @include transition($timing opacity $durationSlow);
        height: 100%;
        width: 100%;

        @include respond($break30) {
          position: absolute;
          background-color: $white;
          opacity: 0;
        }
      }

      .team-member-thumb-info {
        @include transition($timing bottom $durationSlow);
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 18px 30px;

        @include respond($break30) {
          position: absolute;
          bottom: -100%;
        }

        .team-member-bio-toggle {
          @include iconPre($circleCaretDown);
          display: none;
          padding-top: 13px;
          font-size: 20px;
          color: $accentSecondary;

          @include respond($break20) {
            display: block;
          }

          &.active {
            @include iconPre($circleCaretUp);
          }

          &.mobile {
            display: block;

            @include respond($break20) {
              display: none;
            }
          }
        }
      }
    }

    .team-member-bio {
      @include transition($timing max-height $durationSlow);
      position: absolute;
      left: 0;
      top: auto;
      z-index: 1;
      width: 100%;
      max-height: 0;
      overflow-y: hidden;
      background-color: $white;

      @include respond($break100) {
        margin-left: calc((100vw - #{$containerWidth}) / 2);
      }

      &.active {
        max-height: none;
      }

      .icon-close {
        right: 62px;
        top: 40px;
        padding-bottom: 20px;
        color: $accentSecondary;
        text-align: right;

        @include respond($break70) {
          position: absolute;
          padding-bottom: 0;
        }

        &.mobile-only {
          float: right;
          width: 100%;
          padding-top: 19px;
          text-align: right;

          a {
            display: inline-block;
          }

          @include respond($break70) {
            display: none;
          }
        }
      }

      .bio-inner {
        @include clearfix;
        width: 100%;
        padding-top: 0;
        padding-bottom: 40px;

        @include respond($break30) {
          padding-top: 40px;
        }

        .bio-left {
          float: left;
          width: 100%;

          @include respond($break70) {
            width: 32%;
          }
        }

        .bio-right {
          float: left;
          width: 100%;
          padding-top: 12px;

          @include respond($break70) {
            width: 68%;
            padding-top: 0;
            padding-left: 25px;
          }

          &.nopic {
            @include respond($break70) {
              width: 100%;
              padding-top: 0;
              padding-left: 0;
            }
          }
        }

        .team-member-profile-pic {
          width: 100%;
          height: auto;
          border: 4px solid $grayLight;
        }

        .bio-header {
          .horizontal-divider {
            float: none;
            margin-top: 25px;
          }
        }

        .team-member-main {
          padding-top: 24px;
        }

        .horizontal-divider {
          float: left;
          margin-top: 0;

          @include respond($break70) {
            margin-top: 50px;
          }
        }
      }
    }
  }
}

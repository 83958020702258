@use "sass:math";

.grid-block {
  $minDim: 100vw;
  $flexDim: 50vw;

  $flexDimSmall: 25vw;
  $maxDimSmall: $containerWidth * 0.25;

  $flexDimLarge: 33.33vw;
  $maxDimLarge: math.div($containerWidth, 3);
  position: relative;
  overflow: hidden;

  &:hover {
    .grid-background {
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
    }
  }

  .grid {
    @include listUnstyled;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;

    @include respond($break100) {
      width: $containerWidth;
    }

    .grid-item {
      padding: 0;
      display: flex;

      .content-inner {
        display: block;
        width: $minDim;
        // min-height: $minDim;

        @include respond($break20) {
          width: $flexDim;
          // min-height: $flexDim;
        }
      }

      &.small {
        .content-inner {
          @include respond($break90) {
            width: $flexDimSmall;
            // min-height: $flexDimLarge;
          }

          @include respond($break100) {
            width: $maxDimSmall;
            // min-height: $maxDimSmall;
          }
        }
      }

      &.large {
        .content-inner {
          @include respond($break70) {
            width: $flexDimLarge;
            // min-height: $flexDimLarge;
          }

          @include respond($break100) {
            width: $maxDimLarge;
            // min-height: $maxDimLarge;
          }
        }
      }

      &.placeholder-item {
        display: none;

        @include respond($break20) {
          display: flex;
        }
      }
    }
  }

  .grid-background {
    @include transition($timing top $durationSlow, $timing left $durationSlow, $timing right $durationSlow, $timing bottom $durationSlow);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &.with-background .grid-block-cell {
    .cell-background {
      @include transition($durationSlow opacity $timing);
      opacity: 0.9;
    }
  }

  .grid-block-cell {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .icon-container .icon {
        color: $white;
      }

      .grid-link {
        opacity: 0.75;
      }
    }

    .cell-background {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      z-index: -1;
      background-color: $accentQuaternary;
    }

    &.light-gray-background,
    &.gray-background,
    &.white-background {
      .content-main {
        color: $accentTertiary;

        .cell-body a {
          color: inherit;
        }
      }
    }

    &.dark-gray-background,
    &.blue-background,
    &.orange-background {
      .content-main {
        color: $white;

        .cell-body a {
          color: inherit;
        }
      }
    }

    &.light-gray-background {
      .cell-background {
        background-color: $accentQuaternary;
      }
    }

    &.gray-background {
      .cell-background {
        background-color: $grayLight;
      }
    }

    &.dark-gray-background {
      .cell-background {
        background-color: $accentTertiary;
      }
    }

    &.blue-background {
      .cell-background {
        background-color: $accentPrimary;
      }
    }

    &.orange-background {
      .cell-background {
        background-color: $accentSecondary;
      }
    }

    &.white-background {
      .cell-background {
        background-color: $white;
      }
    }

    .content-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      text-align: center;
    }

    .content-main {
      padding: $minContainerPadding;

      @include respond($break70) {
        padding: 34px;
      }

      .cell-heading {
        @include subheadingSecondary;
        padding-bottom: 10px;
      }

      .cell-body {
        @include copySecondary;
      }

      .stylized {
        color: inherit;
        text-transform: uppercase;
        padding-top: 7px;
      }
    }

    .icon-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 25px $minContainerPadding 0 $minContainerPadding;
      min-height: $flexDim;

      @include respond($break20) {
        min-height: $flexDim * 0.5;
        padding: 6vw 5.625vw 0 5.625vw;
      }

      @include respond($break70) {
        padding: 44px 34px 0 34px;
      }

      @include respond($break90) {
        min-height: $flexDimLarge * 0.5;
      }

      .icon {
        @include transition($timing color $durationSlow);
        padding-bottom: 7.6vw;
        color: $accentSecondary;

        @include respond($break20) {
          padding-bottom: 30px;
        }

        &::before {
          text-align: center;
          font-size: 23.2vw;

          @include respond($break20) {
            font-size: 74px;
          }
        }
      }
    }

    &.with-icon {
      .content-inner {
        min-height: $minDim;

        @include respond($break20) {
          min-height: $flexDim;
        }
      }

      .content-main {
        padding: 0 $minContainerPadding 25px $minContainerPadding;

        @include respond($break20) {
          padding: 0 5.625vw 6vw 5.625vw;
        }

        @include respond($break70) {
          padding: 0 34px 44px 34px;
        }
      }

      &.small {
        .content-inner {
          @include respond($break90) {
            // width: $flexDimSmall;
            min-height: $flexDimSmall;
          }

          @include respond($break100) {
            // width: $maxDimSmall;
            min-height: $maxDimSmall;
          }
        }

        .icon-container, .content-main {
          @include respond($break90) {
            min-height: $flexDimSmall * 0.5;
          }

          @include respond($break100) {
            min-height: $maxDimSmall * 0.5;
          }
        }

        .icon {
          &::before {
            @include respond($break100) {
              font-size: 74px;
            }
          }
        }
      }

      &.large {
        .content-inner {
          @include respond($break70) {
            // width: $flexDimLarge;
            min-height: $flexDimLarge;
          }

          @include respond($break100) {
            // width: $maxDimLarge;
            min-height: $maxDimLarge;
          }
        }

        .icon-container, .content-main {
          @include respond($break100) {
            min-height: $maxDimLarge * 0.5;
          }
        }

        .icon {
          &::before {
            @include respond(1009px) {
              font-size: 125px;
            }
          }
        }
      }
    }
  }
}


.two-up-grid {
  @include listUnstyled;

  .grid-item {
    padding: 0;
    background-color: $accentQuaternary;

    @include respond($break60) {
      display: flex;
      min-height: 500px;
    }

    + .grid-item {
      border-top: 15px solid $white;

      @include respond($break60) {
        border: 0;
      }
    }

    &.image-right {
      .grid-item-pic-wrapper {
        order: 2;
      }

      .grid-item-body-wrapper {
        order: 1;
      }
    }

    &.image-left {
      .grid-item-pic-wrapper {
        order: 1;
      }

      .grid-item-body-wrapper {
        order: 2;
      }
    }

    &.white-background {
      background-color: $white;
    }

    &.light-gray-background {
      background-color: $accentQuaternary;
    }

    &.blue-background, &.orange-background, &.dark-gray-background {
      .grid-item-body {
        color: $white;

        h1, h2, h3, h4, h5, h5, h6, h6 {
          color: inherit;
        }

        a {
          color: inherit;
        }

        .stylized {
          color: $white;

          &.gray {
            color: $accentTertiary;
          }
        }
      }
    }

    &.blue-background {
      background-color: $accentPrimary;
    }

    &.orange-background {
      background-color: $accentSecondary;
    }

    &.dark-gray-background {
      background-color: $accentTertiary;
    }

    &.gray-background {
      background-color: $grayLight;

      .grid-item-body {
        a {
          color: inherit;
        }

        .stylized {
          color: $accentSecondary;

          &.gray {
            color: $accentTertiary;
          }
        }
      }
    }

    .grid-item-body-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond($break60) {
        width: 50%;
      }
    }

    .grid-item-body {
      width: 100%;
      padding: 50px $minContainerPadding;

      @include respond($break60) {
        padding: 6.5vw;
      }

      @include respond($break100) {
        padding: 90px;
        padding: 90px;
      }
    }

    .grid-item-pic-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 71.63%;

      @include respond($break60) {
        width: 50%;
        padding-top: 0;
      }


      &:hover {
        .grid-item-pic {
          transform: scale(1.1);
        }
      }
    }

    .grid-item-pic {
      @include transition($timing transform $durationSlow);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(1);
    }
  }
}


.header-wrapper {
  @include transition($timing padding $duration, $timing top $duration);
  background-color: $white;
  position: fixed;
  top: auto;
  width: 100%;
  opacity: 0;

  &.sticky {
    width: 100%;
    opacity: 1;
    z-index: 100;
  }

  &.static {
    position: relative;
    opacity: 1;
  }
}

.site-header {
  @include transition($timing padding $duration, $timing top $duration);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: $containerWidth;
  padding: $minContainerPadding;
  margin: 0 auto;
  opacity: 0;

  @include respond($break20) {
    padding: 35px 45px;
  }

  .sticky & {
    // position: fixed;
    z-index: 100;
    opacity: 1;

    @include respond($break20) {
      padding: 20px 45px;
    }

    @include respond($break100) {
      left: 0;
    }
  }

  .static & {
    position: relative;
    opacity: 1;
  }

  .right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .logo {
    // @include transition($timing max-width $durationSlow);
    max-width: 155px;

    @include respond($break30) {
      max-width: 250px;
    }
  }

  .sticky & .logo {
    @include respond($break30) {
      max-width: 155px;
    }
  }
}

.sticky-site-header-wrapper {
  height: 100% !important;
}

// Component Typography and Prototypes
// -----------------------------------

// Template typography classes and mixins/prototypes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

// Contents
// --------------------------
// - Utilities
// - Prototypes
// - Copy
// - Headings
// - Subheadings
// - Signage
// - Labels
// - Lists
// - Forms

// Copy
// --------------------------------------------------------

@mixin copyPrimary {
  @include font-body;
  font-size: 16px;
  font-weight: $regular;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @include respond($break70) {
    font-size: 20px;
  }
}

@mixin copySecondary {
  @include font-body;
  font-size: 14px;
  font-weight: $regular;
  line-height: 1.588;
  letter-spacing: 0.05em;

  @include respond($break70) {
    font-size: 17px;
  }
}

@mixin copySmall {
  @include font-body;
  font-size: 11px;
  font-weight: $regular;
  line-height: 1.588;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 13px;
  }
}

// Headings
// --------------------------------------------------------

//h1
@mixin headingPrimary {
  @include font-body;
  font-size: 33px;
  font-weight: $light;
  line-height: 1.3;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 60px;
    line-height: 1.2;
  }
}

//h2
@mixin headingSecondary {
  @include font-body;
  font-size: 26px;
  font-weight: $light;
  letter-spacing: 0.1em;
  line-height: 1.26;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 42px;
  }
}

@mixin headingTertiary {
  @include font-body;
  font-size: 22px;
  font-weight: $light;
  letter-spacing: 0.05em;
  line-height: 1.3;

  @include respond($break70) {
    font-size: 30px;
    line-height: 1.3;
  }
}

// Subheadings
// --------------------------------------------------------

//h3
@mixin subheadingPrimary {
  @include font-body;
  font-size: 23px;
  font-weight: $regular;
  line-height: 1.26;
  letter-spacing: 0.05em;

  @include respond($break70) {
    font-size: 36px;
    line-height: 1.166;
  }
}

//h4
@mixin subheadingSecondary {
  @include font-body;
  font-size: 20px;
  font-weight: $bold;
  line-height: 1.4;
  letter-spacing: 0.05em;

  @include respond($break70) {
    font-size: 22px;
    line-height: 1.27;
  }
}

//h5
@mixin subheadingTertiary {
  @include font-body;
  font-size: 16px;
  font-weight: $bold;
  line-height: 1.27;
  letter-spacing: 0.075em;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 18px;
    line-height: 1.55;
  }
}

//h6
@mixin subheadingQuaternary {
  @include font-body;
  font-size: 12px;
  font-weight: $bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 14px;
    line-height: 1.29;
  }
}

// Lists
// --------------------------------------------------------

@mixin listPrimary {
  @include font-body;
  font-size: 15px;
  font-weight: $bold;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin listSecondary {
  @include font-body;
  font-size: 21px;
  font-weight: $regular;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include respond($break20) {
    font-size: 36px;
    font-weight: $light;
  }
}

@mixin listTertiary {
  @include font-body;
  font-size: 21px;
  font-weight: $regular;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include respond($break20) {
    font-size: 20px;
    text-transform: none;
  }
}

@mixin listQuaternary {
  @include font-body;
  color: $accentTertiary;
  font-size: 12px;
  font-weight: $bold;
  line-height: 1;
  text-transform: uppercase;

  @iclude respond($break20) {
    font-size: 13px;
  }
}

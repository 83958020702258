// Component Appearance
// --------------------

// Appearance classes contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Utilities
// --------------------------------------------------------
.accent-primary {
  color: $accentPrimary;
}

.accent-secondary {
  color: $accentSecondary;
}

.accent-tertiary {
  color: $accentTertiary;
}

.accent-quaternary {
  color: $accentQuaternary;
}

.block-title {
  @include headingSecondary;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @include respond($break70) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@mixin stylizedLink {
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
}

// Layout
// --------------------------------------------------------
.horizontal-divider {
  display: block;
  border-color: $grayLight;
  background-color: $grayLight;
  border: 0;
  width: 100%;
  height: 4px;
  margin: 3px auto;
}

// Buttons/Links
// --------------------------------------------------------

@mixin buttonPrimary {
  @include font-body;
  @include buttonUnstyled;
  @include transition(
    $timing background-color $duration,
    $timing opacity $duration
  );
  display: inline-block;
  padding: 15px 26px;
  color: $white;
  background-color: $accentSecondaryLight;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  font-weight: $bold;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include respond($break70) {
    font-size: 16px;
  }

  &:hover {
    background-color: $accentSecondary;
  }
}

.stylized {
  @include subheadingQuaternary;
  @include stylizedLink;
  @include iconPre($circleCaretRight);
  display: inline-block;
  margin-top: 14px;
  color: $accentSecondary;

  &::before {
    margin-top: -3px;
    margin-right: 11px;
    font-size: 20px;
    vertical-align: middle;
  }

  &:hover {
    opacity: 0.75;
  }

  &.gray {
    color: $accentTertiary;
  }
}

.grid-link {
  @include transition($timing opacity $durationSlow);
}

// Icons
// --------------------------------------------------------

.icon-circle-caret-right {
  @include iconPre($circleCaretRight);
  font-size: 20px;
}

.icon-email {
  @include iconPre($email);
  font-size: 15px;
}

.icon-close {
  @include iconPre($close);
  font-size: 36px;
}

.icon-facebook {
  @include iconPre($socialFacebook);
  font-size: 24px;
}

.icon-twitter {
  @include iconPre($socialTwitter);
  font-size: 24px;
}

.icon-linkedin {
  @include iconPre($socialLinkedin);
  font-size: 24px;
}

.icon-instagram {
  @include iconPre($socialInstagram);
  font-size: 24px;
}

.icon-youtube {
  @include iconPre($socialYoutube);
  font-size: 24px;
}

.icon-bim {
  @include iconPre($bim);
}

.icon-collaboration {
  @include iconPre($collaboration);
}

.icon-communication {
  @include iconPre($communication);
}

.icon-construction {
  @include iconPre($construction);
}

.icon-cooperation {
  @include iconPre($cooperation);
}

.icon-design {
  @include iconPre($designBuild);
}

.icon-preconstruction {
  @include iconPre($preconstruction);
}

.icon-preplanning {
  @include iconPre($preplanning);
}

.icon-explore-work {
  @include iconPre($exploreWork);
}

.icon-fairness {
  @include iconPre($fairness);
}

.icon-feasibility {
  @include iconPre($feasibility);
}

.icon-remodels {
  @include iconPre($remodels);
}

.icon-repair {
  @include iconPre($repair);
}

.icon-teamwork {
  @include iconPre($teamwork);
}

.icon-testimonial {
  @include iconPre($testimonial);
}

.icon-affordable-housing {
  @include iconImagePre("../../svgs/Affordable Housing.svg");
}

.icon-hospitality {
  @include iconImagePre("../../svgs/Hospitality.svg");
}

.icon-industrial {
  @include iconImagePre("../../svgs/Industrial.svg");
}

.icon-multifamily {
  @include iconImagePre("../../svgs/Multifamily.svg");
}

.icon-office {
  @include iconImagePre("../../svgs/Office.svg");
}

.icon-retail {
  @include iconImagePre("../../svgs/Retail.svg");
}

.icon-senior-housing {
  @include iconImagePre("../../svgs/Senior Housing.svg");
}

.icon-storage {
  @include iconImagePre("../../svgs/Storage.svg");
}

.icon-colleges {
  @include iconImagePre("../../svgs/Colleges Universities.svg");
}

.icon-high-schools {
  @include iconImagePre("../../svgs/High Schools Trade Schools.svg");
}

.icon-mentoring {
  @include iconImagePre("../../svgs/Mentoring.svg");
}

.icon-minority-owned-businesses {
  @include iconImagePre("../../svgs/Minority Owned Businesses.svg");
}

.m-back-nav-bar {
  &__link {
    display: inline-flex;
    align-items: center;
    padding-block-start: 18px;
    color: $accentTertiary;

    @include iconPre($caretRight);

    &:before {
      transform: rotate(180deg);
      color: $accentSecondary;
      font-size: 8px;
      margin-inline-end: 5px;
    }
  }
}

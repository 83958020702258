$maxDim: 200px;

.m-post {
  &__spacer {
    @include respond($break70) {
      flex: 0 0 $maxDim;
      width: $maxDim;
    }
  }

  &__title {
    padding-block-start: 18px;
    padding-block-end: 24px;
  }

  &__image {
    max-width: 100%;
  }

  &__next-prev-wrapper {
    padding-block-start: 0 !important;
    min-height: 0 !important;
  }

  &__next-prev {
    display: grid;
    grid-gap: 30px;
    grid-template:
      "prev" auto
      "next" auto
      / 1fr;
    width: 100%;
    border-top: 2px solid $accentTertiary;
    padding-block-start: 30px;

    @include respond($break10) {
      grid-template:
        "prev next" auto
        / 1fr 1fr;
    }
  }

  &__prev {
    grid-area: prev;
  }

  &__next {
    grid-area: next;
    text-align: left;

    @include respond($break10) {
      text-align: right;
    }
  }

  &__prev-text,
  &__next-text {
    display: block;
    padding-block-end: 12px;
  }
}

// Anchor Link targets

.anchor-target {
  display: block;
  position: relative;
  visibility: hidden;

  &::before {
    content: '';
  }
}

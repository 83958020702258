// Fonts
// -----


@font-face {
  font-family: 'Akkurat Std';
  src: url('../fonts/AkkuratStd-Light.eot');
  src: url('../fonts/AkkuratStd-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AkkuratStd-Light.woff') format('woff'),
    url('../fonts/AkkuratStd-Light.ttf') format('truetype');
  font-weight: $light;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat Std';
  src: url('../fonts/AkkuratStd-Regular.eot');
  src: url('../fonts/AkkuratStd-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AkkuratStd-Regular.woff') format('woff'),
    url('../fonts/AkkuratStd-Regular.ttf') format('truetype');
  font-weight: $regular;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat Std';
  src: url('../fonts/AkkuratStd-Bold.eot');
  src: url('../fonts/AkkuratStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AkkuratStd-Bold.woff') format('woff'),
    url('../fonts/AkkuratStd-Bold.ttf') format('truetype');
  font-weight: $bold;
  font-style: normal;
}

// @font-face {
//   font-family: 'Akkurat Std';
//   src: url('../fonts/AkkuratStd-LightItalic.eot');
//   src: url('../fonts/AkkuratStd-LightItalic.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/AkkuratStd-LightItalic.woff') format('woff'),
//     url('../fonts/AkkuratStd-LightItalic.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Akkurat Std';
//   src: url('../fonts/AkkuratStd-BoldItalic.eot');
//   src: url('../fonts/AkkuratStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
//     url('../fonts/AkkuratStd-BoldItalic.woff') format('woff'),
//     url('../fonts/AkkuratStd-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
// }


// Mixins
// --------------------------
@mixin font-body {
  font-family: 'Akkurat Std', sans-serif;
}

$desktopBreak: $break90;

.m-posts {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    background-color: $accentQuaternary;

    &--has-image {
      @include respond($desktopBreak) {
        display: grid;
        grid-template:
          "image text" 1fr
          / 322px 1fr;
      }
    }

    &+& {
      margin-block-start: 40px;
    }
  }

  &__item-image {
    grid-area: image;
    max-width: 100%;

    @include respond($desktopBreak) {
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-name {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  &__item-text {
    grid-area: text;
    padding-inline-start: 25px;
    padding-inline-end: 40px;
    padding-block-start: 25px;
    padding-block-end: 40px;
  }

  &__post-category {
    display: block;

    @include respond($break60) {
      display: inline;
      &::before {
        content: ' | ';
      }
    }
  }
}
$className: "m-posts-callout";

.#{$className} {
  &__container {
    max-width: 1215px;
    margin-right: auto;
    margin-left: auto;
    padding-inline-start: 60px;
    padding-inline-end: 60px;
    padding-block-end: 100px;

    &--no-top-padding {
      padding-top: 0;
    }
  }

  &__header {
    padding-bottom: 60px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 50px;
    list-style: none;
    padding: 0;
    margin: 0;

    @include respond($break90) {
      flex-direction: row;
    }

    .no-flexbox-gap & {
      @include respond($break90 - 1, max) {
        > * + * {
          margin-block-start: 50px;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 1 1 auto;

    @include respond($break60) {
      column-gap: 40px;
      flex-direction: row;
    }

    @include respond($break90) {
      flex-direction: column;
      flex-basis: 33.3%;
      row-gap: 30px;
    }
  }

  &__item-text {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @include respond($break60) {
      flex: 1 0 50%;
    }

    .no-flexbox-gap & {
      margin-inline-end: 30px;
    }
  }

  // Override global li padding without using !important
  li.#{$className}__item {
    padding: 0;
  }

  &__item-image-link {
    display: flex;
    flex-basis: 33.3%;
    flex-grow: 1;

    .no-flexbox-gap & {
      margin-inline-end: 30px;
      margin-block-end: 30px !important;
    }
  }

  &__item-image {
    width: 100%;
    height: auto;
  }

  &__item-category {
    margin-block-end: 10px;
  }

  &__item-title {
    display: inline-block;
    line-height: 39pt;

    @include respond($break90) {
      margin-block-end: auto;
    }
  }
}

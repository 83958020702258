.m-category-video {
  margin-block-end: 1rem;
  aspect-ratio: 16 / 9;

  // fallback
  @supports not (aspect-ratio: auto) {
    position: relative;
    padding-top: calc(9 / 16 * 100%);
    height: 0;
    overflow: hidden;
  }

  &__iframe {
    width: 100%;
    height: 100%;

    // fallback
    @supports not (aspect-ratio: auto) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.projects {
  @include clearfix;
  @include listUnstyled;
  padding-top: 20px;

  @include respond($break30) {
    padding-top: 25px;
    margin-left: -25px;
  }

  .project {
    float: left;
    width: 100%;
    padding-bottom: 20px;

    @include respond($break30) {
      width: 50%;
      padding-bottom: 25px;
      padding-left: 25px;
    }

    @include respond($break90) {
      width: 25%;
    }

    .project-thumb {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 1px solid transparent;
      z-index: 0;

      @include respond($break30) {
        &:hover {
          .project-pic {
            transform: scale(1.1);
          }
        }
      }

      .project-pic {
        @include transition($timing transform $durationSlow);
        display: block;
        position: relative;
        z-index: -1;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transform: scale(1);
        overflow: hidden;
        border: 1px solid transparent;
      }
    }

    .project-overlay {
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      cursor: pointer;

      @include respond($break30) {
        position: absolute;

        &:hover {
          .overlay-background {
            opacity: 0.9;
          }

          .project-thumb-info {
            bottom: 0;
          }
        }
      }

      .overlay-background {
        @include transition($timing opacity $durationSlow);
        height: 100%;
        width: 100%;

        @include respond($break30) {
          position: absolute;
          background-color: $white;
          opacity: 0;
        }
      }

      .project-thumb-info {
        @include transition($timing bottom $durationSlow);
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 20px 18px 0px;

        @include respond($break30) {
          position: absolute;
          bottom: -100%;
          padding: 18px 30px;
        }

        .project-name {
          @include headingTertiary;
          color: $accentPrimary;
        }

        .project-criteria {
          @include subheadingQuaternary;
          color: $accentSecondary;
          padding-top: 10px;
        }

        .icon-circle-caret-right {
          display: none;
          padding-top: 13px;

          @include respond($break30) {
            display: block;
          }
        }
      }
    }
  }
}

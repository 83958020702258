$desktopBreak: $break70;

.m-events {
  &__form {
    text-align: right;
    padding-block-end: 20px;
  }

  &__select {
    position: relative;
    display: inline-flex;
    align-items: center;

    @include iconPost($caretRight);

    &:after {
      position: absolute;
      inset-inline-end: 0;
      pointer-events: none;
      transform: rotate(90deg);
      color: $accentSecondary;
      font-size: 0.75em;
      margin-inline-end: 5px;

      @supports not (inset-inline-end: 0) {
        right: 0;
      }
    }
  }

  &__select-input {
    @include inputUnstyled;
    @include subheadingQuaternary;
    flex: 1 1 auto;
    padding: 10px;
    padding-inline-end: 22px;
    text-align: right;

    &:focus {
      outline-color: $accentSecondary;
    }
  }

  &__main {
    width: 100%;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    background-color: $accentQuaternary;
    grid-template:
      "date" auto
      "text" 1fr
      / 1fr;

    @include respond($desktopBreak) {
      display: grid;
      grid-template:
        "date text" 1fr
        / 220px 1fr;
    }

    & + & {
      margin-block-start: 20px;
      padding-top: 0;
    }
  }

  &__item-date {
    grid-area: date;
    height: 100%;
    width: 100%;
  }

  &__item-name {
    padding-block-end: 5px;
  }

  &__date-location {
    padding-block-end: 10px;
  }

  &__item-text {
    grid-area: text;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    padding-block-start: 25px;
    padding-block-end: 25px;
  }
}

.site-footer {
  .nav {
    @include listHorizontal;
  }

  .tertiary-nav {
    .nav-item {
      display: block;

      @include respond($break70) {
        display: inline-block;
      }
    }
  }

  .top-link {
    @include iconPre($caretUp);
    float: right;
    color: $accentSecondary;

    span {
      @include transition($timing color $duration);
      @include subheadingQuaternary;
      display: none;
      color: $white;
      text-transform: uppercase;

      @include respond($break70) {
        display: inline-block;
        padding-left: 10px;
      }
    }

    &::before {
      font-size: 16px;

      @include respond($break70) {
        font-size: 8px;
      }
    }

    &:hover {
      label {
        color: $accentSecondary;
      }
    }
  }

  .offices-nav {
    padding-top: 20px;
    background-color: $blueDark;
    color: $white;

    @include respond($break20) {
      padding-top: 4.77vw;
    }

    @include respond(985px) {
      padding-top: 47px;
    }

    .footer-container {
      padding-bottom: 0;
      padding-top: 0;
    }

    .nav-heading {
      @include headingTertiary;
      padding-bottom: 20px;
      text-transform: uppercase;

      @include respond($break20) {
        padding-bottom: 0;
      }
    }

    .nav-item {
      display: block;

      @include respond($break70) {
        display: inline-block;
        margin-right: 60px;
      }

      + .nav-item {
      }

      a {
        @include copyPrimary;
        @include iconPost($caretRight);
        display: block;
        color: $white;

        @include respond($break20) {
          padding-top: 20px;
        }

        &::after {
          padding-left: 10px;
          color: $accentSecondary;
          font-size: 10px;

          @include respond($break20) {
            padding-left: 16px;
            font-size: 16px;
            vertical-align: middle;
          }
        }

        &:hover {
          color: $accentSecondary;
          font-weight: $regular;
        }
      }
    }
  }

  .sub-footer {
    @include clearfix;
    width: 100%;
    background-color: $blueDark;

    .horizontal-divider {
      display: none;
      width: 100%;
      height: 1px;
      background-color: $grayMedium;
      border: 0;
      margin-bottom: 20px;

      @include respond($break60) {
        display: block;
      }
    }

    .external-link {
      display: block;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 13px;
      font-weight: $regular;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $grayMedium;
      border-top: 1px solid $grayMedium;

      @include respond($break60) {
        float: left;
        padding-top: 2px;
        padding-bottom: 17px;
        border: 0;
      }

      &:hover {
        color: $accentSecondary;
      }
    }

    .footer-social {

      @include respond($break60) {
        margin-right: 36px;
      }
    }
  }

  .social-nav {
    display: block;
    padding-top: 12px;
    border-top: 1px solid $grayMedium;

    @include respond($break60) {
      float: right;
      padding-top: 0;
      margin-top: -2px;
      border: 0;
    }

    .nav-item + .nav-item {
      margin-left: 6px;
    }

    .social-link {
      line-height: 1;
      cursor: pointer;

      &::before {
        color: $grayMedium;
      }

      &:hover {
        &::before {
          color: $accentSecondary;
        }
      }
    }
  }
}

$maxDim: 220px;
$minDim: 150px;

.m-event {
  &__date-aside {
    padding-inline-end: 30px;
  }

  &__title {
    padding-block-start: 30px;
    padding-block-end: 9px;
  }

  &__time {
    padding-block-end: 5px;
  }

  &__description {
    padding-block-start: 24px;
  }
}

.offices {
  @include listUnstyled;

  .office {
    background-color: $accentQuaternary;

    @include respond($break60) {
      display: flex;
    }

    + .office {
      border-top: 15px solid $white;

      @include respond($break60) {
        border: 0;
      }
    }

    .first-loc-target {
      position: relative;
      top: 32px;

      @incldu respond($break60) {
        position: static;
      }
    }

    .office-info {
      @include subheadingTertiary;
      display: flex;
      flex-direction: column;
      justify-contents: center;
      width: 100%;
      padding: 50px $minContainerPadding;
      color: $accentTertiary;
      text-transform: none;
      font-weight: $regular;

      @include respond($break60 - 1, max) {
        order: 1 !important;
      }

      @include respond($break60) {
        width: 50%;
        padding: 6.5vw;
        order: inherit;
      }

      @include respond($break100) {
        padding: 90px;
        padding: 90px;
      }

      .office-city {
        @include subheadingPrimary;
      }

      .office-address-1 {
        padding-top: 18px;
      }

      .office-address-2 {
        padding-top: 10px;
      }

      .office-tel {
        padding-top: 18px;
      }

      .office-fax {
        padding-top: 10px;
      }

      .office-key-contact {
        @include iconPre($circleCaretRight);
        @include stylizedLink;
        @include subheadingQuaternary;
        display: block;
        padding-top: 16px;
        color: $accentSecondary;

        &::before {
          padding-right: 7px;
          vertical-align: middle;
          font-size: 20px;
        }
      }
    }

    .office-pic-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 71.63%;

      @include respond($break60 - 1, max) {
        order: 2 !important;
      }

      @include respond($break60) {
        width: 50%;
        padding-top: 0;
      }

      &:hover {
        .office-pic {
          transform: scale(1.1);
        }
      }
    }

    .office-pic {
      @include transition($timing transform $durationSlow);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(1);
    }
  }
}

.hamburger-accordion {
  display: inline-block;
  height: 100%;

  .hamburger-toggle {
    display: block;
    position: relative;
    overflow: hidden;
    margin-left: 42px;
    margin-top: 1px;
    width: 30px;
    height: 28px;
    cursor: pointer;

    span {
      @include transition(background 0s $timing $duration);
      display: block;
      position: absolute;
      top: 13px;
      left: 0px;
      right: 0px;
      height: 2px;
      background: $accentSecondary;

      &::before,
      &::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $accentSecondary;
        content: "";
        transform: rotate(0deg);
      }

      &::before {
        top: -9px;
        @include transition(
          top $duration $timing $duration,
          transform $duration $timing 0s
        );
      }

      &::after {
        bottom: -9px;
        @include transition(
          bottom $duration $timing $duration,
          transform $duration $timing 0s
        );
      }
    }

    &.active {
      span {
        background: none;

        &::before {
          top: 0;
          transform: rotate(45deg);
          @include transition(
            top $duration $timing 0s,
            transform $duration $timing $duration
          );
        }

        &::after {
          bottom: 0;
          transform: rotate(-45deg);
          @include transition(
            bottom $duration $timing 0s,
            transform $duration $timing $duration
          );
        }
      }
    }
  }

  .hamburger-menu {
    @include transition($timing max-height $durationSlow);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 0;
    overflow-y: auto;
    background-color: $accentQuaternary;
    z-index: 12;

    &.active {
      max-height: 100vh;
      border: 1px solid $grayLight;
    }

    &.desktop {
      display: none;

      @include respond($break20) {
        display: block;
      }
    }

    &.mobile {
      display: block;

      @include respond($break20) {
        display: none;
      }
    }

    > .nav {
      @include listUnstyled;
      display: block;
      max-width: 400px;
      padding: $minContainerPadding;
      margin: 0 auto;

      @include respond($break20) {
        padding-top: 8.018vw;
        padding-bottom: 14.32vw;
      }

      .nav-item {
        &.mobile-only {
          display: block;

          @include respond($break70) {
            display: none;
          }
        }

        a {
          @include listSecondary;
          color: $accentPrimary;
          font-weight: $light;
          text-transform: uppercase;
        }
      }

      .nested-nav {
        @include listUnstyled;
        padding-left: 28px;

        .nested-nav-item a {
          @include listTertiary;
          color: $accentTertiary;
        }
      }

      .nested-nav-item,
      .nav-item {
        a {
          display: block;

          &:hover {
            color: $accentSecondary;
          }
        }

        &.active {
          a {
            color: $accentSecondary;
          }
        }
      }
    }
  }

  .hamburger-menu.mobile {
    &.active {
      .nav {
        position: fixed;
        left: 0;
        right: 0;
      }
    }

    .nav {
      .nav-item,
      .nested-nav-item {
        border-bottom: 1px solid $grayLight;

        &.active {
          a {
            color: $accentSecondary;
          }
        }
      }

      .nested-nav {
        padding-left: 0;
        border-top: 1px solid $grayLight;
      }

      .nested-nav-item a,
      .nav-item a,
      .panel-toggle {
        display: block;
        padding-top: 10px;
        padding-bottom: 9px;
        font-weight: $regular;
        color: $accentPrimary;

        &:hover {
          color: $accentSecondary;
        }
      }
    }

    .panel-toggle {
      @include transition($timing color $duration);
      @include iconPost($caretRight);
      @include listSecondary;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;

      &::after {
        position: absolute;
        right: 0;
        top: 17px;
        font-size: 16px;
        color: $accentSecondary;
      }
    }

    .nav-panel {
      @include transition($timing left $durationSlow);
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 100vw;
      padding: $minContainerPadding;
      background-color: $accentQuaternary;

      &.open {
        left: 0;
      }

      .back-link {
        @include subheadingQuaternary;
        @include iconPre($caretLeft);
        display: block;
        margin-bottom: 13px;
        color: $accentSecondary;
        cursor: pointer;

        &::before {
          padding-right: 12px;
          margin-top: -2px;
          vertical-align: middle;
          font-size: 16px;
        }
      }
    }
  }
}

.primary-nav {
  @include listHorizontal;
  @include listPrimary;
  display: none;

  @include respond($break70) {
    display: inline-block;
  }

  .nav-item {
    display: inline-block;
    padding-top: 2px;

    a {
      color: $accentPrimary;

      &:hover {
        color: $accentSecondary;
      }
    }

    + .nav-item {
      padding-left: 44px;
    }

    &.active {
      a {
        color: $accentSecondary;
      }
    }
  }
}

.sticky-sub-nav,
.sticky-sub-nav-wrapper {
  height: 100% !important;
}

.sub-nav-wrapper,
.secondary-nav-wrapper {
  background-color: $accentQuaternary;
  position: fixed;
  top: auto;
  display: block;
  width: 100%;
  opacity: 0;
  z-index: 0;

  .sticky ~ main .sticky & {
    width: 100%;
    opacity: 1;
    z-index: 100;
  }

  &.static {
    position: relative;
    opacity: 1;
    z-index: 10;

    @include respond($break30) {
      z-index: 0;
    }
  }
}

.sticky ~ main {
  .sticky .secondary-nav-wrapper,
  .sticky .sub-nav-wrapper {
    width: 100%;
    opacity: 1;
    z-index: 100;

    @include respond($break20 - 1, max) {
      opacity: 0;
    }
  }
}

.search-wrapper,
.more-wrapper {
  @include transition($timing border-bottom $durationSlow);
  background-color: $white;
  border-bottom: 0;

  &.active {
    border-bottom: 1px solid $grayLight;
  }
}

//Anchor or Filter nav
.secondary-nav {
  @include transition($timing top $durationSlow);
  display: block;
  flex-wrap: nowrap;
  // position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;

  @include respond($break20) {
    display: flex;
  }

  hr {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 1px;
    margin: 0;
    border: 0;
    background-color: $white;

    .sticky & {
      display: block;
    }
  }

  ul {
    @include listHorizontal;
    text-align: center;
    padding: 25px $minContainerPadding;

    .nav-item {
      padding: 0 1.2vw;

      a {
        @include listQuaternary;
        letter-spacing: 0.1em;

        &:hover {
          color: $accentSecondary;
        }
      }

      &.active {
        a {
          color: $accentSecondary;
        }
      }
    }
  }

  .filter-nav {
    display: none;

    @include respond($break20) {
      display: block;
    }
  }

  .anchor-nav {
    .nav-item {
      @include subheadingQuaternary;
      width: 100%;
      text-align: center;

      @include respond($break20) {
        display: inline-block;
        width: auto;
      }
    }
  }

  .search-link {
    @include iconPre($magnifyingGlass);
    display: none;
    padding: 25px 0;
    margin-right: $minContainerPadding;

    @include respond($break20) {
      display: block;
    }

    &::before {
      color: $accentTertiary;
      vertical-align: middle;
      font-size: 26px;
    }

    &.active,
    &:hover {
      &::before {
        color: $accentSecondary;
      }
    }
  }

  .mobile-filters-toggle {
    @include listQuaternary;
    @include iconPost($circleCaretDown);
    display: inline-block;
    width: 100%;
    padding: 22px 45px 22px 22px;
    line-height: 1.4;

    @include respond($break20) {
      display: none;
    }

    &:hover {
      color: $accentSecondary;
    }

    &.selected {
      @include iconPost($close);

      &::after {
        padding-left: 12px;
        margin-top: -2px;
        font-size: 14px;
        color: $accentSecondary;
        vertical-align: middle;
      }
    }

    &::after {
      position: absolute;
      font-size: 16px;
      color: $accentSecondary;
      right: 22px;
      top: calc(50% - 8px);
    }

    .mobile-filter-indicator {
      .indicator-inner {
        color: $accentSecondary;
      }
    }
  }

  .mobile-filters-container {
    position: relative;

    @include respond($break20) {
      display: none;
    }
  }

  .mobile-filters-header {
    @include listQuaternary;
    padding: 20px;
    color: $accentSecondary;
    border-bottom: 1px solid $grayLight;

    .mobile-filters-heading {
      display: inline-block;
      width: calc(100% - 16px);
    }

    .close {
      @include iconPost($close);
      display: inline-block;
      color: $accentSecondary;

      &::after {
        font-size: 13px;
      }
    }
  }

  .mobile-filters {
    display: none;
    position: fixed;
    top: 97px;
    left: $minContainerPadding;
    right: $minContainerPadding;
    z-index: 10;
    padding-bottom: 50px;
    background-color: $white;

    &.active {
      display: block;
    }

    @include respond($break20) {
      display: none;

      &.active {
        display: none;
      }
    }

    ul {
      @include listUnstyled;
      height: 435px;
      padding: 0 $minContainerPadding;
      overflow-y: scroll;

      .nav-item {
        display: block;
        width: 100%;
        margin-top: 25px;
        text-align: left;
      }
    }
  }
}

.tertiary-nav {
  @include clearfix;
  background-color: $accentPrimary;

  .nav {
    float: left;
  }

  .nav-item {
    + .nav-item {
      margin-top: 6px;

      @include respond($break70) {
        margin-top: 0;
        margin-left: 45px;
      }
    }

    a {
      @include subheadingQuaternary;
      display: block;
      color: $white;

      &:hover {
        color: $accentSecondary;
      }
    }
  }
}

.more-nav {
  @include transition($timing max-height $durationSlow);
  max-height: 0;
  overflow-y: hidden;
  background-color: $white;

  &.active {
    max-height: 250px;
  }

  ul {
    padding: $minContainerPadding 0;
  }
}

.search-bar {
  @include transition($timing max-height $durationSlow);
  max-height: 0;
  overflow-y: hidden;
  background-color: $white;

  &.active {
    max-height: 250px;
  }

  .icon-search {
    @include iconPre($magnifyingGlass);
    color: $accentSecondary;

    &::before {
      font-size: 20px;
    }
  }

  .search-criteria {
    width: calc(100% - 40px);
    border: 0;

    @include respond($break20) {
      width: calc(100% - 80px);
    }

    &:focus {
      outline: 0;
    }
  }

  .search-close {
    @include iconPre($close);
    color: $accentSecondary;

    &::before {
      font-size: 20px;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    padding: $minContainerPadding;
  }

  &.mobile {
    position: relative;
    display: block;
    max-height: none;
    overflow-y: auto;

    @include respond($break20) {
      display: none;
    }

    .inner {
      padding: 18px 45px 18px 18px;
    }

    .mobile-search-reset {
      @include iconPost($close);
      position: absolute;
      right: 22px;
      top: calc(50% - 10px);
      cursor: pointer;

      &::after {
        font-size: 16px;
        color: $accentSecondary;
      }
    }
  }
}

.project-detail-pagination {
  @include listUnstyled;
  display: flex;
  padding-top: 20px;
  flex-direction: column;

  @include respond($break20) {
    align-items: center;
    padding-top: 25px;
    margin-left: -12.5px;
    margin-right: -12.5px;
  }

  @include respond($break90) {
    flex-direction: row;
  }

  .pagination-nav-item {
    width: 100%;
    padding-bottom: 20px;

    @include respond($break20) {
      max-width: 383px;
      padding-bottom: 25px;
      padding-left: 12.5px;
      padding-right: 12.5px;
    }

    @include respond($break90) {
      width: 33.33%;
      max-width: none;
    }

    &.right {
      text-align: right;
    }

    &.center {
      .pagination-thumb {
        &:hover {
          .pagination-label,
          .pagination-name,
          .icon-grid {
            color: $accentPrimary;
          }
        }
      }

      .pagination-thumb-info {
        position: static;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        text-align: center;

        .pagination-label,
        .pagination-name,
        .icon-grid {
          @include transition($timing color $duration);
        }
      }
    }

    .pagination-thumb {
      display: block;
      position: relative;
      overflow: hidden;

      &:hover {
        .pagination-pic {
          transform: scale(1.1);
        }
      }
    }

    .pagination-pic {
      @include transition($timing transform $durationSlow);
      display: block;
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(1);
    }

    .pagination-background {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 33.33%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $accentQuaternary;

      @include respond($break90) {
        height: 0;
        padding-top: 100%;
      }
    }

    .pagination-overlay {
      @include transition($timing opacity $durationSlow);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      opacity: 1;
      overflow: hidden;

      @include respond($break70) {
        opacity: 0;

        &:hover {
          opacity: 1;

          .pagination-thumb-info.animatable {
            bottom: 0;
          }
        }
      }

      &.always-on {
        opacity: 1;
      }

      .overlay-background {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $white;
        opacity: 0.9;
      }

      .pagination-thumb-info {
        @include transition($timing bottom $durationSlow);
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
        padding: 18px 20px;

        @include respond($break20) {
          padding: 18px 30px;
        }

        @include respond($break70) {
          &.animatable {
            bottom: -100%;
          }
        }

        .pagination-label {
          @include font-body;
          padding-top: 12px;
          font-size: 12px;
          font-weight: $bold;
          line-height: 1.5;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $accentSecondary;

          &.invisible {
            visibility: hidden;
          }
        }

        .pagination-name {
          @include transition($timing color $duration);
          @include font-body;
          padding-top: 6px;
          font-size: 14px;
          font-weight: $regular;
          line-height: 1.6;
          letter-spacing: 0.025em;
          color: $accentPrimary;

          @include respond($break90) {
            font-size: 16px;
          }

          &.invisible {
            visibility: hidden;
          }
        }

        .icon-caret-right {
          @include iconPre($caretRight);

          &::before {
            font-size: 30px;
          }
        }

        .icon-caret-left {
          @include iconPre($caretLeft);

          &::before {
            font-size: 30px;
          }
        }

        .icon-grid {
          @include iconPre($grid);

          &::before {
            font-size: 23px;
          }
        }
      }
    }
  }
}

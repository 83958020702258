.m-rte {
  @include copySecondary;

  p {
    @include copySecondary;
  }

  > * + * {
    margin-block-start: 1.3em;
  }

  @include headings {
    + * {
      margin-block-start: 0.5rem !important;
    }
  }

  .fr-inner {
    font-style: italic;
    color: $accentTertiaryLight;
  }

  img {
    max-width: 100%;
  }
}

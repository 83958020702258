.hero {
  position: relative;
  min-height: 460px;
  .limited {
    inline-size: min(100vw, 1650px);
  }

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.small {
    $flexHeight: 32vw;
    $maxHeight: $containerWidth * 0.32;
    height: $flexHeight;

    @include respond($break100) {
      height: $maxHeight;
    }

    .hero-overlay {
      height: $flexHeight;
      min-height: 460px;

      @include respond($break100) {
        min-height: $maxHeight;
      }
    }

    .hero-video,
    .fluid-vid,
    .fluidvids {
      @include fluidVid($flexHeight, $maxHeight);
    }
  }

  &.large {
    $flexHeight: 50vw;
    $maxHeight: $containerWidth * 0.5;
    height: $flexHeight;

    @include respond($break100) {
      height: $maxHeight;
    }

    .hero-overlay {
      // height: $flexHeight;
      min-height: 460px;

      // @include respond($break100) {
      //   min-height: $maxHeight;
      // }
    }

    .hero-video,
    .fluid-vid,
    .fluidvids {
      @include fluidVid($flexHeight, $maxHeight);
    }
  }

  &:hover,
  &.hovered {
    .hover-overlay {
      opacity: 0.8;
      background-color: $blueDark;
    }

    .hero-overlay .hero-body {
      background-color: transparent;
    }
  }

  .hero-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .hover-overlay {
    @include transition($durationSlow opacity $timing);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: $accentTertiary;
  }

  .hero-overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    color: $white;
    text-shadow: -2px 2px 10px $accentTertiary;

    .hero-body,
    .hero-button {
      position: relative;
      z-index: 10;
    }

    .hero-body {
      background-color: rgba($blueDark, 0.7);
      @include transition($durationSlow background-color $timing);
      width: 100%;
      padding: 1.75rem 2.5rem 2.5rem;
      margin-top: -1.75rem;
      margin-left: -2.5rem;
    }

    .hero-button {
      @include buttonPrimary;
      margin-top: 30px;
      opacity: 0.9;
      text-shadow: none;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.hero-slideshow {
  @include transition($timing opacity $durationSlow);

  .slideshow-container {
    position: relative;
    overflow: hidden;
  }

  .slideshow-indicator {
    position: absolute;
    bottom: 6px;
    width: 100%;
    text-align: center;
    z-index: 10;

    @include respond($break20) {
      bottom: 12px;
    }

    .dot {
      @include transition(0.6s opacity $timing);
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: $accentSecondary;
      border-radius: 5px;
      opacity: 0.5;
      cursor: pointer;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);

      + .dot {
        margin-left: 5px;
      }

      &.active {
        opacity: 1;
      }
    }
  }

  .slides {
    @include listUnstyled;
    @include transition(0.6s transform $timing);
    display: flex;
    height: 100%;
    transform: translateX(0vw);

    .limited {
      align-items: center;
      justify-content: center;
    }

    li {
      width: 100vw;
      height: 100%;
    }
    li.limited {
      display: flex;
      justify-content: center;
    }
  }

  .hero-slide {
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      .limited {
        inline-size: 1650px;
      }
    }

    &.small {
      .hero-overlay {
        // height: 32vw;

        // @include respond($break100) {
        //   height: $containerWidth * 0.32;
        // }
      }
    }

    &.large {
      .hero-overlay {
        // height: 50vw;

        // @include respond($break100) {
        //   height: $containerWidth * 0.5;
        // }
      }
    }
  }

  .slide-button-container {
    @include transition($timing opacity $durationSlow);
    position: absolute;
    top: auto;
    bottom: 0;
    display: none;
    width: 50%;
    height: auto;
    opacity: 1;
    z-index: 1;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
      text-align: right;
    }

    @include respond($break20) {
      display: block;
      height: 100%;
      top: 0;
    }

    @include respond($break70) {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }

    .page-left,
    .page-right {
      @include buttonUnstyled;
      height: 100%;
      padding-bottom: 12px;

      @include respond($break20) {
        padding-bottom: 0px;
      }

      &::before {
        font-size: 50px;
        color: $white;

        @include respond($break20) {
          font-size: 62px;
        }
      }
    }

    .page-left {
      @include iconPre($caretLargeLeft);
      padding-left: 12px;
      filter: drop-shadow(2px 2px 2px $accentTertiary);
    }

    .page-right {
      @include iconPre($caretLargeRight);
      padding-right: 12px;
      filter: drop-shadow(2px 2px 2px $accentTertiary);
    }
  }
}

.hero-video {
  .fluidvids,
  .fluidvid {
    visibility: hidden;
  }

  .inner-wrapper {
    position: relative;
  }

  .hero-overlay {
    position: absolute;
  }

  @include respond($break20) {
    .fluidvids,
    .fluidvid {
      visibility: visible;
    }
  }
}

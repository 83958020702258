// Content
// -------

.hero-body,
.content-main,
.team-member-main,
.grid-item-body {
  a {
    &:hover {
      opacity: 0.75;
    }
  }

  p {
    &.small {
      @include copySecondary;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + p {
      margin-block-start: 1rem;
    }
  }

  h1,
  .heading-1 {
    @include headingPrimary;
  }

  h2,
  .heading-2 {
    @include headingSecondary;
  }

  h3,
  .sub-heading-1 {
    @include subheadingPrimary;
  }

  h4,
  .sub-heading-2 {
    @include subheadingSecondary;
  }

  h5,
  .sub-heading-3,
  .sub-heading-3-orange {
    @include subheadingTertiary;
  }

  h6,
  .sub-heading-4,
  .sub-heading-4-orange {
    @include subheadingQuaternary;
  }

  ol,
  ul {
    li {
      @include copySecondary;

      + li {
        @include respond($break20) {
          padding-top: 6px;
        }
      }
    }
  }

  blockquote {
    padding-block-end: 1rem;
  }

  .button,
  a.button {
    @include buttonPrimary;
    margin-top: 30px;
    color: $white;
  }

  .orange {
    color: $accentSecondary !important;
  }

  .blue {
    color: $accentPrimary !important;
  }
}

.content-block .content-main {
  width: 100%;

  a {
    color: $accentSecondary;

    &.stylized {
      color: $accentSecondary;

      &.gray {
        color: $accentTertiary;
      }
    }
  }
}

.content-wrapper {
  position: relative;
  color: var(--color, $accentTertiary);

  &__image-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    pointer-events: none;
    object-fit: cover;
  }

  &.white-background {
    background-color: $white;
  }

  &.gray-background {
    background-color: $accentQuaternary;
  }

  &.blue-background {
    background-color: $accentPrimary;
    --color: #{$white};
    --header-color: #{$white};
  }
}

.content-block,
.grid-block {
  h1,
  .heading-1 {
    color: var(--header-color, $accentPrimary);
  }

  h2,
  .heading-2 {
    color: var(--header-color, $accentPrimary);
  }

  h3,
  .sub-heading-1 {
    color: var(--color, $accentTertiary);
  }

  h4,
  .sub-heading-2 {
    color: var(--color, $accentTertiary);
  }

  h5,
  .sub-heading-3 {
    color: var(--color, $accentTertiary);
  }

  h5.orange,
  .sub-heading-3-orange {
    color: $accentSecondary;
  }

  h6,
  .sub-heading-4 {
    color: $accentTertiary;
  }

  h6.orange,
  .sub-heading-4-orange {
    color: $accentSecondary;
  }

  .button,
  a.button {
    margin-top: 30px;
    color: $white;
  }

  .centered {
    width: 100%;
    text-align: center;

    ul,
    ol {
      text-align: left;
    }
  }
}

.content-block {
  padding-top: 50px;
  padding-bottom: 50px;

  @include respond($break70) {
    padding-top: 6.5vw;
    padding-bottom: 6.5vw;
  }

  @include respond($break100) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  &.tall {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  &.short {
    padding-top: 50px;
    padding-bottom: 23px;

    @include respond($break70) {
      padding-top: 6.5vw;
      padding-bottom: 3.01vw;
    }

    @include respond($break100) {
      padding-top: 90px;
      padding-bottom: 38px;
    }
  }

  .content-inner {
    display: block;
    @include respond($break60) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.content-aside {
  $maxDim: 200px;
  // width: 100%;
  padding: 0;
  text-align: center;

  @include respond($break60) {
    // width: calc(#{$maxDim} + (#{$contentContainerPadding} * 0.4));
    padding-right: $contentContainerPadding * 0.4;
  }

  @include respond($break100) {
    // width: calc(#{$maxDim} + (#{$maxContentContainerPadding} * 0.4));
    padding-right: $maxContentContainerPadding * 0.4;
  }

  .content-image {
    display: inline-block;
    max-width: $maxDim;
    max-height: $maxDim;
    padding-bottom: 50px;

    @include respond($break60) {
      padding-bottom: 0;
    }
  }

  .icon {
    display: inline-block;
    padding-bottom: 50px;

    &::before {
      font-size: 85px;
      text-align: center;
      color: $accentSecondary;
    }

    @include respond($break60) {
      width: $maxDim;
      height: $maxDim;
      padding-bottom: 0;

      &::before {
        font-size: 118px;
      }
    }
  }

  .icon-testimonial {
    &::before {
      font-size: 120px;
      color: $grayMedium;
    }

    @include respond($break60) {
      &::before {
        font-size: 163px;
      }
    }
  }
}

.content-testimonial {
  blockquote {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}

.hidden-content-alert {
  padding: 30px;
  text-align: center;
  font-weight: 600;
  font-size: large;
  background-color: yellow;
}

.two-column {
    &.white-background {
        background-color: $white;
      }
    
      &.gray-background {
        background-color: $accentQuaternary;
      }
    
      &.blue-background {
        background-color: $accentPrimary;
        --color: #{$white};
        --header-color: #{$white};
      }
}

.two-column-header {
  padding-bottom: 2rem;
}

.two-column-body {
    @include respond($break70) {
        display: flex;
        column-gap: 3rem;
    }
}

.two-column-item {
    & + & {
      padding-top: 2rem;
    }

    figure {
      margin: 1em 0px;

      h3 {
        margin-bottom: 1em;
      }

      .image-element {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
      
      figcaption {
        @include copySmall;
      }
    }

    @include respond($break70) {
        flex: 50%;

        & + & {
          padding-top: 0;
        }
    }
}
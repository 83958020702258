.m-form {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 660px;

  &__fields {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-block-start: 40px;
  }

  @include respond($break60) {
    width: 50vw;
  }

  &__field {
    flex: 1 1 100%;

    &--50 {
      @include respond($break20) {
        flex-basis: calc(50% - 1rem);
      }
    }
  }

  &__input {
    width: 100%;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-block-start: 0.75rem;
    padding-block-end: 0.75rem;
    color: $accentTertiary;
    border: 1px solid $accentQuaternary;

    &:focus {
      outline-color: $accentPrimary;
    }

    &::placeholder {
      color: $accentTertiary;
      opacity: 1;
    }
  }

  &__submit.button {
    width: 100%;

    @include respond($break10) {
      padding-inline-start: 3rem;
      padding-inline-end: 3rem;
      width: auto;
    }
  }
}
